import React, { useState } from "react";
import Breadcrumbsbanner from "../breadcumbbanner/breadcumbsbanner";
import { Modal } from "react-bootstrap";

import { Controller, useForm } from "react-hook-form";
import {
  bloodGroup,
  genderList,
  ageList,
  bibLocation,
  globalbibLocation,
  tShirtSizes,
  dateconvert,
  accommodation,
  ageCalculator,
  datecalendarconvert,
  giftTreeCounts,
  centerList,
} from "../../../assets/data/commonData";
import PhoneInput from "react-phone-input-2";
import deletebin from "../../../assets/images/myregisteration/trash-fill.svg";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

// import logOutimg from "../../../assets/images/logOut.png";

import { navigate } from "gatsby";
import { useEffect } from "react";
import KanhaRunService from "../../services/kanha.service";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import { PhoneNumberUtil } from "google-libphonenumber";
import backn from "../../../assets/images/backIconSummary.svg";
import "react-toastify/dist/ReactToastify.css";
import config from "../../../assets/config";
import { CreateImageFromInitials, GetRandomColor } from "../../utils/common";
import moment from "moment";
import { lStorage } from "../../utils/storage";
import countriesData from "../../../pages/country_code.json";
import Select from "react-select";
import axios from "axios";

export default function BasicFormRegister() {
  const datePickerId = new Date().toISOString().slice(0, 10);
  const minDateValue = "1920-01-06";
  const [pList, setPlist] = useState(lStorage.get("list"));
  const skuList = lStorage.get("skuData");
  const [formData, setFormData] = useState(pList);
  const [raceValue, setRaceValue] = useState([]);
  const [accommidationStatus, setAccommidationStatus] = useState("");
  const [raceList, setRaceList] = useState([]);
  const [raceError, setRaceError] = useState(false);
  const [sameNumberError, setSameNumber] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [secretKey, setSecretKey] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [isDeleted, setIsDeleted] = useState();
  // const [isMobile, setIsMobile] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countriesData[0]);
  const [emCountryCode, setEmCountryCode] = useState();
  const [showBib, setShowBib] = useState("");
  const [isContactValid, setIsContactValid] = useState();
  const [isEcontactValid, setIsEcontactValid] = useState();
  const [show, setShow] = useState(false);
  const [globalRun, setGlobalRun] = useState(0);
  const [raceCountry, setRaceCountry] = useState("");
  const [raceState, setRaceState] = useState("");
  const [raceCity, setRaceCity] = useState("");
  const [raceType, setRaceType] = useState("");
  const [selectedRaceCatId, setSelectedRaceCatId] = useState();
  const [showBibAddress, setShowBibAddress] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isSpecialDonation = lStorage.get("specialDonation");

  const kanhaApi = new KanhaRunService();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    setFocus,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const count = watch("gift_a_tree_count", 0); // Initialize with a default value
  const increment = () => {
    setValue("gift_a_tree_count", parseInt(count) + 1);
  };

  const decrement = () => {
    if (count > 0) {
      setValue("gift_a_tree_count", count - 1);
    }
  };

  const clearModal = () => {
    window.location.reload();
  };

  const runCount = watch("gift_a_run", 0);
  const runIncrement = () => {
    setValue("gift_a_run", parseInt(runCount) + 1);
  };

  const runDecrement = () => {
    if (runCount > 0) {
      setValue("gift_a_run", runCount - 1);
    }
  };

  const [inputValue, setInputValue] = useState("");
  const [selectedCityOption, setSelectedCityOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [cityNameError, setCityNameError] = useState("");

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
  };

  const handleInputChangeOption = (newValue) => {
    setSelectedCityOption(newValue);
    setCityNameError("");
  };
  // useEffect(() => {
  //   const selectedLocation =  lStorage.get("selectedLocation");
  //   if (selectedLocation !== undefined || null) {
  //     setRaceCountry(selectedLocation.country);
  //     setRaceState(selectedLocation.state);
  //     setRaceCity(selectedLocation.city);
  //   }
  // }, []);
  const cityName = watch("city_name", 0);
  useEffect(() => {
    if (cityName) {
      setSelectedCityOption({ value: cityName, label: cityName });
    }
  }, [cityName]);
  useEffect(() => {
    if (inputValue.length < 3) {
      setOptions([]);
      return;
    }

    const url = `https://static-gatsby.web.app/srcmapi/cities/${inputValue
      .slice(0, 3)
      .toLowerCase()}.json`;
    axios
      .get(url)
      .then((response) => {
        if (Array.isArray(response.data.results)) {
          /*const results = response.data.results.reduce((acc, current) => {
            const x = acc.find(item => item.name === current.name);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);*/

          const mappedOptions = response.data.results.map((result) => ({
            value: result.name,
            label: result.name + " - " + result.state + " - " + result.country,
          }));
          setOptions(mappedOptions);
        } else {
          console.error(
            "Data is not in the expected array format:",
            response.data
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [inputValue]);

  const [countryCode, setCountryCode] = useState("91");

  const onSubmit = async (data) => {
    // if (!selectedCityOption) {
    //   setCityNameError("This field is required");
    //   return;
    // }
    const prevData = formData == null ? [] : formData;

    const datass = {
      ...data,
      ...(countryCode && { countrycode: countryCode }),
      ...(emCountryCode && { em_country_code: emCountryCode }),
      city_name: selectedCityOption ? selectedCityOption.value : "",
      email_address: data?.email_address
        ? data?.email_address.replace(/\s+$/, "")
        : "",
    };

    let split = data.race_category_id.split(",");
    let v1 = split[0];
    let v2 = split[1];
    let v3 = split[2];
    const years = ageCalculator(data.dob);
    let valid = "";
    if (v1 <= 4) {
      valid =
        years < 8 && v1 == 1
          ? "valid"
          : years >= 8 && (v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 15 && (v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : years >= 18 && (v1 == 4 || v1 == 3 || v1 == 2 || v1 == 1)
          ? "valid"
          : "notvalid";
    } else {
      valid = "valid";
    }
    let mobileValid =
      data.contact_number !== data.emergence_contact_number
        ? "valid"
        : "mobile number same";
        console.log(valid);
    if (valid === "valid" && mobileValid === "valid") {
      setAccommidationStatus("");
      const birdValues =
        raceList.earlybird == "YES"
          ? "hfn-special-discount"
          : "hfn-non-early-bird";
      const categoryVal =
        data.overnight_accommodation == "Y" ? "with-dorm" : "without-dorm";
      const accomodation_price = data.overnight_accommodation == "Y" ? 500 : 0;
      const bib_delivery_price =
        data.bib_collection_location == "Your Home Address" ? 119 : 0;
      if(data.bib_collection_location == "Nearest Heartfulness Centre" || v1 == 5){
        datass["address"] = "";
        datass["pincode"] = "";
      }
      let concatValues = "";
      if (v1 <= 4) {
        concatValues = [v2].join("-");
      } else {
        concatValues = [v2].join("-");
      }

      let removeData = "";

      if (config.environment === "staging") {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      } else {
        removeData = skuList && skuList.filter((e) => e.code == concatValues);
      }
      if (JSON.parse(localStorage.getItem("list"))) {
        const applyData = JSON.parse(localStorage.getItem("list"));
        /*datass["discount_code"] =applyData[0].discount_code ;
        datass["discount_name"]=applyData[0].discount_name;
        datass["discount_price"] =applyData[0].discount_price;*/
      }
      var treePrice = 0;
      var runPrice = 0;
      if (raceType === "Timed Run" || raceType === "Global Run") {
        treePrice = 500;
        runPrice = 600;
      } else {
        treePrice = 2000;
        runPrice = 1000;
      }
      // For GST Need to replace this code *** race_price ***
      // race_price: Math.round(
      //   removeData[0].rate +
      //     (removeData[0].gstPercentage / 100) * removeData[0].rate
      // ) + (Number(data?.gift_a_tree_count) * treePrice)+ (Number(data?.gift_a_run) * runPrice)+accomodation_price+bib_delivery_price,
      const newData = {
        ...datass,
        dob: dateconvert(datass.dob),
        race_category_id: v1,
        gift_a_tree_count: Number(data?.gift_a_tree_count),
        gift_a_run: Number(data?.gift_a_run),
        t_shirt_size: v1 <= 4 || v1 == 7 || v1 == 6 ? data.t_shirt_size : "",
        overnight_accommodation: v1 <= 4 || v1 == 9 ? data.overnight_accommodation : "",
        bib_collection_location:
          v1 <= 4 || v1 == 7 || v1 == 6 ? data.bib_collection_location : "",
        sku_id: removeData[0].id,
        race_km: v2,
        sku_title: removeData[0].title,
        race_price:
          Math.round(removeData[0].rate) +
          Number(data?.gift_a_tree_count) * treePrice +
          Number(data?.gift_a_run) * runPrice +
          accomodation_price +
          bib_delivery_price,
        race_category_name: v3,
        secretId: Math.floor(Math.random() * Date.now()),
        discount_price: 0,
        final_amount:
          Math.round(removeData[0].rate) +
          Number(data?.gift_a_tree_count) * treePrice +
          Number(data?.gift_a_run) * runPrice +
          accomodation_price +
          bib_delivery_price,
        discount_code: "",
        discount_name: "",
      };
      if (isEdit === false) {
        await prevData.push(newData);
        lStorage.set("list", prevData);
        await setFormData(prevData);
        // window.scrollTo(0, 0);
        toast.success("Participant added successfully");
        setTimeout(() => {
          navigate("/successfulparticipants");
        }, 1000);
        await reset();
      } else if (isEdit === true) {
        var newIndex;
        (() => {
          pList?.map((item, index) => {
            if (secretKey === item.secretId) {
              newIndex = index;
              return index;
            }
          });
          pList?.splice(newIndex, 1, newData);
        })();

        lStorage.set("list", pList);
        setIsUpdated(true);
        toast.success("Participant updated successfully");
        setTimeout(() => {
          navigate("/successfulparticipants");
        }, 1000);
        setIsEdit(false);
        lStorage.remove("editSecretId");
        await reset();
      }
    } else {
      if (mobileValid === "mobile number same") {
        // toast.error(
        //   // "Contact number and Emergency contact number should not be same"
        // );
        setSameNumber(true);
        window.scrollTo(0, 0);
      } else {
        // toast.error(
        //   "Your age  is not eligible for participant in this selected Race"
        // );
        setRaceError(true);
        setFocus("race_category_id");
      }
    }
  };

  var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  const getraceList = () => {
    const selectedLocation = lStorage.get("selectedLocation");
    if (selectedLocation !== undefined || null) {
      setRaceCountry(selectedLocation.country);
      setRaceState(selectedLocation.state);
      setRaceCity(selectedLocation.city);
      setRaceType(selectedLocation.runType);
    }

    let stateId = selectedLocation.state_id;
    let cityId = selectedLocation.city_id;

    kanhaApi
      .getracecategory(stateId, cityId)
      .then((response) => {
        if (
          response.data.isError === false ||
          response.data.statusCode === "200"
        ) {
          if (response.data.isError === false) {
            const globalRun = lStorage.get("globalRun");
            setRaceList(response?.data?.data);
            // if(globalRun==1){
            //   if (countryCode === "91") {
            //     const indRaceList = response?.data?.data
            //    /* const defaultInCountry = removeByAttr(indRaceList, 'race_slug', '2k-race');
            //     const defaultInCountry1 = removeByAttr(defaultInCountry, 'race_slug', '5k-race');
            //     const defaultInCountry2= removeByAttr(defaultInCountry1, 'race_slug', '10k-race');
            //     const defaultInCountry3 = removeByAttr(defaultInCountry2, 'race_slug', '21k-race');
            //     const defaultInCountry4 = removeByAttr(defaultInCountry3, 'race_slug', 'virtual-run-400');
            //     const defaultInCountry5 = removeByAttr(defaultInCountry4, 'race_slug', 'virtual-run-399');
            //     const defaultInCountry6= removeByAttr(defaultInCountry5, 'race_slug', 'virtual-run-599');
            //     const defaultInCountry7 = removeByAttr(defaultInCountry6, 'race_slug', 'virtual-run-99');
            //  */   setRaceList(indRaceList);
            //   } else{
            //     const indRaceList = response?.data?.data
            //   /*  const defaultInCountry = removeByAttr(indRaceList, 'race_slug', '2k-race');
            //     const defaultInCountry1 = removeByAttr(defaultInCountry, 'race_slug', '5k-race');
            //     const defaultInCountry2= removeByAttr(defaultInCountry1, 'race_slug', '10k-race');
            //     const defaultInCountry3 = removeByAttr(defaultInCountry2, 'race_slug', '21k-race');
            //     const defaultInCountry4 = removeByAttr(defaultInCountry3, 'race_slug', 'virtual-run-99');
            //     const defaultInCountry5 = removeByAttr(defaultInCountry4, 'race_slug', 'virtual-run-399');
            //     const defaultInCountry6= removeByAttr(defaultInCountry5, 'race_slug', 'virtual-run-599');
            //     */
            //     setRaceList(indRaceList);
            //   }
            // }else {
            //   if (countryCode === "91") {
            //     const indRaceList = response?.data?.data
            //   /*  const defaultInCountry = removeByAttr(indRaceList, 'race_slug', '2k-race');
            //     const defaultInCountry1 = removeByAttr(defaultInCountry, 'race_slug', '5k-race');
            //     const defaultInCountry2= removeByAttr(defaultInCountry1, 'race_slug', '10k-race');
            //     const defaultInCountry3 = removeByAttr(defaultInCountry2, 'race_slug', '21k-race');
            //     const defaultInCountry4 = removeByAttr(defaultInCountry3, 'race_slug', 'virtual-run-400');
            //     const defaultInCountry5 = removeByAttr(defaultInCountry4, 'race_slug', 'virtual-run-399');
            //     const defaultInCountry6= removeByAttr(defaultInCountry5, 'race_slug', 'virtual-run-599');
            //     const defaultInCountry7 = removeByAttr(defaultInCountry6, 'race_slug', 'virtual-run-99');
            //    */
            //     setRaceList(indRaceList);
            //   }
            //   else{
            //     const OtherRaceList = response?.data?.data;
            //     OtherRaceList?.splice(4, 3);
            //     const defaultInCountry = removeByAttr(OtherRaceList, 'race_slug', '2k-race');
            //     const defaultInCountry1 = removeByAttr(defaultInCountry, 'race_slug', '5k-race');
            //     const defaultInCountry2= removeByAttr(defaultInCountry1, 'race_slug', '10k-race');
            //     const defaultInCountry3 = removeByAttr(defaultInCountry2, 'race_slug', '21k-race');

            //     setRaceList(defaultInCountry3);
            //   }

            // }
          }
          //  navigate("/basicformpage");
        }
      })
      .catch(function (error) {
        toast.error("Invalid Token");
        logOut();
      });
  };
  const raceListsss = () => {
    kanhaApi
      .raceList()
      .then((response) => {
        if (response.status == "200") {
          lStorage.set("skuData", response.data.sku);
        }
      })
      .catch(function (error) {
        toast.error("something went wrong");
        logOut();
      });
  };
  const logOut = () => {
    lStorage.remove("userInfo");
    lStorage.remove("verification_id");
    lStorage.remove("list");
    lStorage.remove("globalRun");
    lStorage.remove("appliedCouponCode");
    navigate("/");
  };
  const backTo = () => {
    navigate("/myregisterationlist");
  };

  const deleteData = async (data) => {
    let removeData = formData && formData.filter((e) => e !== data);
    lStorage.set("list", removeData);
    const parseList = removeData;
    await setFormData(parseList);
  };

  useEffect(() => {
    const receCatId = lStorage.get("selectedRace");
    setSelectedRaceCatId(receCatId);
    const toEditSecretId = lStorage.get("editSecretId");
    if (toEditSecretId !== undefined || null) {
      const editDataObject = lStorage.get("list")?.filter((item) => {
        if (toEditSecretId === item.secretId) {
          return item;
        }
      })?.[0];
      setTimeout(() => {
        // editDataObject.race_category_id=editDataObject?.race_category_id+"," +editDataObject?.race_km+"," +editDataObject?.race_category_name;
        editData(editDataObject);
        setIsEdit(true);
      }, 1000);
    }
  }, []);

  const raceCatSelected = () => {
    const raceData = lStorage.get("selectedRace");
    const raceValue = [
      raceData?.race_category_id,
      raceData?.race_km,
      raceData?.race_category_name,
    ].join(",");

    setShowBib(raceValue);
    setRaceValue(Number(raceData?.race_category_id));
    setValue("race_category_id", raceValue);
  };

  useEffect(() => {
    raceCatSelected();
  }, []);

  const editData = async (data) => {
    console.log("data", data)
    setCountryCode(data?.countrycode);
    setIsUpdated(false);
    setSecretKey(data?.secretId);
    setRaceValue(Number(data?.race_category_id));

    setValue("race_category_id",data?.race_category_id+"," +data?.race_km+"," +data?.race_category_name);

    setShowBib(
      data?.race_category_id +
        "," +
        data?.race_km +
        "," +
        data?.race_category_name
    );
    setShowBibAddress(data?.bib_collection_location);

    const fields = [
      "first_name",
      "last_name",
      "email_address",
      "contact_number",
      "gender",
      "age",
      "blood_group",
      "eContact",
      "emergence_contact_number",
      // "country_code",
      "address",
      "city_name",
      "name_on_bib",
      "t_shirt_size",
      "bib_collection_location",
      "overnight_accommodation",
      "sku_id",
      "race_km",
      "sku_title",
      "race_price",
      "race_category_name",
      "pincode",
      "race_category_id",
      // "em_country_code",
      // "countrycode",
      "organisation_name",
      "gift_a_tree_count",
      "gift_a_run",
    ];
    fields.forEach(
      (field) => setValue(field, data?.[field]),
      setValue(
        "dob",
        data?.dob && data?.dob ? datecalendarconvert(data?.dob) : ""
      )
      /*  setValue(
        "emergence_contact_number",
        data?.emergence_contact_number && data?.emergence_contact_number
          ? `${"+" + data.em_country_code + " " + data.emergence_contact_number}`
          : ""
      ),
      setValue(
        "contact_number",
        data?.contact_number && data?.contact_number
          ? `${"+" + data.country_code + " " + data.contact_number}`
          : ""
      ),*/
      // setValue(
      //   "race_category_id",
      //   data.dob && data.dob
      //     ? [
      //         {
      //           race_category_id: Number(data.race_category_id),
      //           race_name: data.race_category_name,
      //           race_price: data.race_price,
      //           race_slug: data.race_km,
      //         },
      //       ]
      //     : ""
      // )
    );

    setTimeout(() => {
      setValue(
        "race_category_id",
        data?.race_category_id +
          "," +
          data?.race_km +
          "," +
          data?.race_category_name
      );
      setValue("contact_number", data?.contact_number);
    }, 1500);

    setAccommidationStatus(data?.overnight_accommodation);
  };

  const backToList = () => {
    setIsEdit(false);
    lStorage.remove("editSecretId");
    navigate(
      formData && formData?.length > 0
        ? "/successfulparticipants"
        : "/myregisterationlist"
    );
  };

  useEffect(() => {
    const isGlobalRun = lStorage.get("globalRun");
    if (isGlobalRun === 1) {
      setGlobalRun(1);
      if (showBib == "") {
        setShowBib("virtual");
        console.log("test");
      }
    }
    setPlist(lStorage.get("list"));
  }, [isEdit]);

  useEffect(() => {
    setPlist(lStorage.get("list"));
  }, [isDeleted]);

  useEffect(() => {
    getraceList();
    raceListsss();
  }, [formData]);

  useEffect(() => {
    if (isUpdated === true) {
      setPlist(lStorage.get("list"));
      setIsEdit(false);
    }
  }, [isUpdated]);

  useEffect(() => {
    setFormData(pList);
  }, [pList]);

  useEffect(() => {
    if (countryCode) {
      getraceList();
    }
  }, [countryCode]);

  // useEffect(() => {
  //   window.innerWidth < 768 ? setIsMobile(true) : setIsMobile(false);
  // }, []);

  const validatePhoneNumber = (phoneNumber) => {
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
  };

  return (
    <>
      <Breadcrumbsbanner />
      <div
        className="Basic-form-wrapper inner-pages p-4"
        style={{ marginBottom: "120px" }}
      >
        <div className="container-fluid">
          <div className="col-md-12">
            <div className="row">
              <div className="p-2 col-md-3">
                <Button
                  className="btn btn-primary"
                  // style={{ margin: "0 12px" }}
                  onClick={backToList}
                >
                  <img className="img-fluid" src={backn} alt="back" />
                  {formData && formData?.length > 0
                    ? "Back to My Summary"
                    : "Back to My Registration"}
                </Button>
              </div>
              <div className="p-2 col-md-9">
                {raceCountry === "Overseas" && (
                  <>
                    <h3>Country : {raceState}</h3>
                  </>
                )}
                {raceCountry !== "Overseas" && (
                  <>
                    <h4>
                      State: {raceState}
                      {","} Center Name: {raceCity}
                    </h4>
                  </>
                )}
              </div>
              {/* {isMobile && formData && formData?.length > 0 && (
                <div className="col-md-3" style={{ paddingBottom: "20px" }}>
                  <div className="participantsform-wrapper">
                    <div>
                      <h3> Participants </h3>
                      {formData &&
                        formData.map((data, index) => {
                          return (
                            <div
                              className="participants-box-wrapper first-upper"
                              key={index + 1}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <span className="particiapnts-icon">
                                    <img
                                      id="preview"
                                      width="35px"
                                      src={CreateImageFromInitials(
                                        500,
                                        data.first_name,
                                        GetRandomColor()
                                      )}
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <div
                                    className="deleteiocn"
                                    onClick={() => {
                                      editData(data);
                                      setIsEdit(true);
                                    }}
                                    style={{
                                      marginRight: "35px",
                                    }}
                                    title="Edit"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-pen"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                                    </svg>
                                  </div>{" "}
                                  <div
                                    className="deleteiocn"
                                    onClick={() => {
                                      deleteData(data);
                                      setIsDeleted(pList?.length - 1);
                                    }}
                                    title="Delete"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={deletebin}
                                      alt="Clock logo"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p>
                                  <b>Name :</b> {data.first_name}{" "}
                                </p>
                                <p>
                                  {" "}
                                  <span>
                                    <b>Gender :</b> {data.gender}
                                  </span>
                                </p>
                                <span>
                                  <b>Race Category :</b> {data.race_km}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      {((pList && pList === null) ||
                        (pList && pList.length === 0)) && (
                        <span>No Participants</span>
                      )}

                      <Button
                        className="btn hand viewbutton"
                        disabled={pList?.length === 0 ? true : false}
                        onClick={() => {
                          navigate("/successfulparticipants");
                        }}
                      >
                        {" "}
                        View Summary & Pay
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}
              <div>
                <h3> Basic Details</h3>
              </div>
              <div className="col-md-9">
                {isEdit == false && pList && pList.length >= 5 && (
                  <span>
                    <b>Maximun 5 Participant only allowed per registration</b>
                  </span>
                )}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="first_name">
                          First Name <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("first_name", {
                            required: true,
                            pattern: /^\S[a-zA-Z .]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.first_name?.type === "required" &&
                            "This field is required."}
                          {errors.first_name &&
                            errors.first_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.first_name &&
                            errors.first_name.type === "pattern" && (
                              <span>Please enter valid name</span>
                            )}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="last_name">
                          Last Name <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("last_name", {
                            required: true,
                            pattern: /^\S[a-zA-Z .]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.last_name?.type === "required" &&
                            "This field is required."}
                          {errors.last_name &&
                            errors.last_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.last_name &&
                            errors.last_name.type === "pattern" && (
                              <span>Please enter valid name</span>
                            )}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="email_address" className="form-label">
                          Email Address
                          <span className="requiredColor">*</span>
                        </label>
                        <input
                          type="email_address"
                          className="form-control"
                          name="email_address"
                          id="email_address"
                          autoComplete="new-password"
                          {...register("email_address", {
                            required: true,
                            pattern: {
                              value:
                                /^([\w.-]+)@(\[(\d{1,3}\.){3}|(?!test|testing)(([a-zA-Z\d-]+\.)+))([a-zA-Z ]{2,4}|\d{1,3})(\]?)$/,
                              message: "Please enter a valid email",
                            },
                          })}
                          onChange={() => {
                            clearErrors("email_address");
                          }}
                        />
                        <span className="error_validation ml-3">
                          {errors.email_address?.type === "required" &&
                            "This field is required."}
                          {errors.email_address?.message}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="contact_number" className="form-label">
                          Contact No<span className="requiredColor">*</span>
                        </label>

                        <Controller
                          className=""
                          control={control}
                          name="contact_number"
                          rules={{
                            required: true,
                          }}
                          render={(props) => {
                            return (
                              <PhoneInput
                              country={raceType === "Overseas" ? "us" : "in"}
                                value={props.field.value}
                                name={props.field.name}
                                onChange={(
                                  value,
                                  country,
                                  e,
                                  formattedValue
                                ) => {
                                  // if (countryCode != country?.dialCode) {
                                  //   setValue("race_category_id", "");
                                  // }
                                  setCountryCode(country?.dialCode);
                                  props.field.onChange(formattedValue);
                                  setSameNumber(false);
                                  clearErrors("contact_number");
                                  setIsContactValid(
                                    validatePhoneNumber(
                                      formattedValue?.replace(/[- ]/g, "")
                                    )
                                  );
                                }}
                                inputRef={props.field.ref}
                                enableSearch={true}
                                className="phoneInput"
                              />
                            );
                          }}
                        />

                        <span className="error_validation ml-3">
                          {errors.contact_number?.type === "required" &&
                            "This field is required."}
                          {errors.contact_number?.message}
                        </span>
                        <span className="error_validation ml-3">
                          {isContactValid === false &&
                            errors.contact_number?.type !== "required" &&
                            "Invalid Phone Number."}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          htmlFor="race_category_id"
                          className="form-label "
                        >
                          Race Category
                          <span className="requiredColor">*</span>
                        </label>
                        <select
                          name="race_category_id"
                          className="form-control"
                          {...register("race_category_id", {
                            required: true,
                          })}
                          autoComplete="new-password"
                          onChange={(e) => {
                            setRaceError(false);
                            clearErrors("race_category_id");
                            const raceCategoryVal = e?.target?.value;
                            setShowBib(raceCategoryVal);
                            if (raceCategoryVal.includes("virtual")) {
                              setValue("blood_group", "");
                              setValue("dob", "");
                              setValue("eContact", "");
                              setValue("emergence_contact_number", "");
                              setValue("bib_collection_location", "");
                              setValue("overnight_accommodation", "");
                            } else {
                              setValue("age", "");
                            }
                          }}
                          value={showBib}
                        >
                          <option value="">Select</option>
                          {raceList &&
                            raceList.sort((a, b) => a.race_price - b.race_price)
                            .map((item, index) => (
                              <option
                                key={index + 1}
                                value={[
                                  item?.race_category_id,
                                  item?.race_slug,
                                  item?.race_name,
                                ].join(",")}
                              >
                                {item?.title}
                              </option>
                            ))}
                        </select>
                        <span className="error_validation ml-3">
                          {errors.race_category_id?.type === "required" &&
                            "This field is required."}
                          {raceError &&
                            "Your age is not eligible to participate in this selected race."}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="gender" className="form-label">
                          Gender<span className="requiredColor">*</span>
                        </label>
                        <select
                          name="gender"
                          className="form-control"
                          {...register("gender", { required: true })}
                        >
                          <option value="">Select</option>
                          {genderList.map((item, index) => {
                            return (
                              <option key={index + 1} value={item.value}>
                                {item.label}
                              </option>
                            );
                          })}
                        </select>
                        <span className="error_validation ml-3">
                          {errors.gender?.type === "required" &&
                            "This field is required."}
                        </span>
                      </div>
                    </div>

                    {!showBib?.includes("virtual") && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="blood_group" className="form-label">
                            Blood Group<span className="requiredColor">*</span>
                          </label>
                          <select
                            name="blood_group"
                            className="form-control"
                            {...register("blood_group", { required: true })}
                          >
                            <option value="">Select</option>
                            {bloodGroup.map((item, index) => {
                              return (
                                <option key={index + 1} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                          <span className="error_validation ml-3">
                            {errors.blood_group?.type === "required" &&
                              "This field is required."}
                          </span>
                        </div>
                      </div>
                    )}

                    {!showBib?.includes("virtual") && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="dob" className="form-label">
                            Date of Birth{" "}
                            <span className="requiredColor">*</span>
                          </label>
                          <div>
                            <input
                              type="date"
                              {...register("dob", {
                                required: true,
                              })}
                              max={datePickerId}
                              min={minDateValue}
                              autoComplete="new-password"
                              className="form-control"
                              onChange={() => {
                                setRaceError(false);
                                clearErrors("dob");
                              }}
                            />
                          </div>
                          <span className="error_validation ml-3">
                            {errors.dob?.type === "required" &&
                              "This field is required."}
                          </span>
                        </div>
                      </div>
                    )}
                    {showBib?.includes("virtual") && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label htmlFor="gender" className="form-label">
                            Age
                          </label>
                          <select
                            name="age"
                            className="form-control"
                            {...register("age")}
                          >
                            <option value="">Select</option>
                            {ageList.map((item, index) => {
                              return (
                                <option key={index + 1} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                    {!showBib?.includes("virtual") && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label className="form-label" htmlFor="eContact">
                            Emergency Contact Name{" "}
                            <span className="requiredColor">*</span>
                          </label>
                          <input
                            {...register("eContact", {
                              required: true,
                              pattern: /^\S[a-zA-Z ]*$/,
                              maxLength: 120,
                            })}
                            autoComplete="new-password"
                            className="form-control"
                          />
                          <span className="error_validation ml-3">
                            {errors.eContact?.type === "required" &&
                              "This field is required."}
                            {errors.eContact &&
                              errors.eContact.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.eContact &&
                              errors.eContact.type === "pattern" && (
                                <span>Please enter valid name</span>
                              )}
                          </span>
                        </div>
                      </div>
                    )}
                    {!showBib?.includes("virtual") && (
                      <div className="col-md-4">
                        <div className="form-group">
                          <label
                            className="form-label"
                            htmlFor="emergence_contact_number"
                          >
                            Emergency Contact Number{" "}
                            <span className="requiredColor">*</span>
                          </label>
                          <Controller
                            className="form-control"
                            control={control}
                            name="emergence_contact_number"
                            rules={{
                              required: true,
                            }}
                            render={(props) => {
                              return (
                                <PhoneInput
                                  country={"in"}
                                  value={props.field.value}
                                  name={props.field.name}
                                  onChange={(
                                    value,
                                    country,
                                    e,
                                    formattedValue
                                  ) => {
                                    setEmCountryCode(country?.dialCode);
                                    props.field.onChange(formattedValue);
                                    setSameNumber(false);
                                    clearErrors("emergence_contact_number");
                                    setIsEcontactValid(
                                      validatePhoneNumber(
                                        formattedValue?.replace(/[- ]/g, "")
                                      )
                                    );
                                  }}
                                  inputRef={props.field.ref}
                                  enableSearch={true}
                                  className="phoneInput"
                                />
                              );
                            }}
                          />
                          <span className="error_validation ml-3">
                            {errors.emergence_contact_number?.type ===
                              "required" && "This field is required."}
                            {errors.emergence_contact_number?.message}
                          </span>
                          <span className="error_validation ml-3">
                            {isEcontactValid === false &&
                              errors.emergence_contact_number?.type !==
                                "required" &&
                              "Invalid Phone Number."}
                          </span>
                          <span className="error_validation ml-3">
                            {sameNumberError &&
                              "Contact number and Emergency contact number should not be same"}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label mrtop10"
                          htmlFor="organisation_name"
                        >
                          Organisation / Club{" "}
                        </label>
                        <input
                          {...register("organisation_name", {
                            // required: true,
                            pattern: /^\S[a-zA-Z0-9\.@ ]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.organisation_name?.type === "required" &&
                            "This field is required."}
                          {errors.organisation_name &&
                            errors.organisation_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.organisation_name &&
                            errors.organisation_name.type === "pattern" && (
                              <span>
                                Please enter valid Organisation / Club name
                              </span>
                            )}
                        </span>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label mrtop10"
                          htmlFor="organisation_name"
                        >
                          City Name
                        </label>
                        {/* <input
                          {...register("city_name", {
                            required: true,
                            pattern: /^\S[a-zA-Z ]*$/,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        /> */}
                        <Select
                          autoComplete="new-password"
                          name="city_name"
                          {...register("city_name", {
                            pattern: /^\S[a-zA-Z ]*$/,
                            maxLength: 120,
                          })}
                          inputValue={inputValue}
                          onInputChange={handleInputChange}
                          value={selectedCityOption}
                          onChange={handleInputChangeOption}
                          options={options}
                          filterOption={(option, searchText) =>
                            option.label
                              .toLowerCase()
                              .includes(searchText.toLowerCase())
                          }
                          isSearchable={true}
                          menuIsOpen={inputValue.length >= 3}
                          isClearable={true}
                          placeholder="Search"
                        />

                        <span className="error_validation ml-3">
                          {cityNameError && (
                            <div style={{ color: "red" }}>{cityNameError}</div>
                          )}
                          {errors.city_name &&
                            errors.city_name.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.city_name &&
                            errors.city_name.type === "pattern" && (
                              <span>Please enter valid city name</span>
                            )}
                        </span>
                      </div>
                    </div>
                    {(showBib?.includes("399") || showBib?.includes("599")) && raceType === "Global Run" && (
                      <>
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="bib_collection_location"
                                className="form-label"
                              >
                                Choose your Race Kit Collection Location
                                <span className="requiredColor">*</span>
                              </label>
                                <select
                                  name="bib_collection_location"
                                  className="form-control"
                                  {...register("bib_collection_location", {
                                    required: true,
                                  })}
                                  onChange={(e) => {
                                    const bibCollection = e?.target?.value;
                                    setShowBibAddress(bibCollection);
                                    if (bibCollection !== "") {
                                      clearErrors("bib_collection_location");
                                    }
                                  }
                                }
                                >
                                  <option value="">Select</option>
                                  {globalbibLocation.map((item, index) => {
                                    return (
                                      <option
                                        key={index + 1}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.bib_collection_location?.type ===
                                  "required" && (
                                  <span className="error_validation ml-3">
                                    {errors.bib_collection_location?.type ===
                                      "required" && "This field is required."}
                                    <br />
                                  </span>
                                )}
                              <p className="mb-0 note_para">
                              For home address only, additional delivery charges Rs.119/- 
                              </p>
                            </div>
                          </div>
                      </>
                    )}

                    {(showBib?.includes("399") || showBib?.includes("599")) && showBibAddress?.includes("Your Home Address")  && raceType === "Global Run" && (
                      <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="address" className="form-label">
                          Address<span className="requiredColor">*</span>
                        </label>
                        <textarea
                          className="form-control address Current_Address"
                          name="address"
                          {...register("address", {
                            required: true,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                        />
                        <span className="error_validation ml-3">
                          {errors.address?.type === "required" &&
                            "This field is required."}
                          {errors.address &&
                            errors.address.type === "maxLength" && (
                              <span>Max length 120 characters allowed</span>
                            )}
                          {errors.address &&
                            errors.address.type === "pattern" && (
                              <span>Please enter only alpha numeric</span>
                            )}
                        </span>
                        {!showBib?.includes("virtual") && (
                          <p className="note_para">
                            International participants are requested to enter
                            their stay address in India
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                    <div className="form-group">
                      <label className="form-label" htmlFor="pincode">
                        Pincode
                        <span className="requiredColor">*</span>
                      </label>
                      <input
                        {...register("pincode", {
                          required: true,
                          pattern: /^[0-9]*$/,
                          maxLength: 10,
                          minLength: 4,
                        })}
                        autoComplete="new-password"
                        className="form-control"
                      />
                      <span className="error_validation ml-3">
                        {errors.pincode?.type === "required" &&
                          "This field is required."}
                        {errors.pincode &&
                          errors.pincode.type === "maxLength" && (
                            <span>Max length exceeded</span>
                          )}
                        {errors.pincode &&
                          errors.pincode.type === "minLength" && (
                            <span>Please enter valid pincode</span>
                          )}
                        {errors.pincode &&
                          errors.pincode.type === "pattern" && (
                            <span>Please enter numeric</span>
                          )}
                      </span>
                    </div>
                  </div>
                  </>
                  )}

                  {raceType !== "Global Run" && (
                    <>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label htmlFor="address" className="form-label">
                          Address<span className="requiredColor">*</span>
                        </label>
                        <textarea
                          className="form-control address Current_Address"
                          name="address"
                          {...register("address", {
                            required: true,
                            maxLength: 120,
                          })}
                          autoComplete="new-password"
                        />
                        <span className="error_validation ml-3">
                          {errors.address?.type === "required" &&
                            "This field is required."}
                          {errors.address &&
                            errors.address.type === "maxLength" && (
                              <span>Max length 120 characters allowed</span>
                            )}
                          {errors.address &&
                            errors.address.type === "pattern" && (
                              <span>Please enter only alpha numeric</span>
                            )}
                        </span>
                        {!showBib?.includes("virtual") && (
                          <p className="note_para">
                            International participants are requested to enter
                            their stay address in India
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="pincode">
                          Pincode
                          <span className="requiredColor">*</span>
                        </label>
                        <input
                          {...register("pincode", {
                            required: true,
                            pattern: /^[0-9]*$/,
                            maxLength: 10,
                            minLength: 4,
                          })}
                          autoComplete="new-password"
                          className="form-control"
                        />
                        <span className="error_validation ml-3">
                          {errors.pincode?.type === "required" &&
                            "This field is required."}
                          {errors.pincode &&
                            errors.pincode.type === "maxLength" && (
                              <span>Max length exceeded</span>
                            )}
                          {errors.pincode &&
                            errors.pincode.type === "minLength" && (
                              <span>Please enter valid pincode</span>
                            )}
                          {errors.pincode &&
                            errors.pincode.type === "pattern" && (
                              <span>Please enter numeric</span>
                            )}
                        </span>
                      </div>
                    </div>
                    </>
                  )}
                    <div className="col-md-4">
                      <div className="form-group">
                        <label
                          className="form-label"
                          htmlFor="gift_a_tree_count"
                        >
                          Gift a Tree
                        </label>
                        <div className="input-with-buttons">
                          <button type="button" onClick={decrement}>
                            -
                          </button>
                          <input
                            type="text"
                            id="gift_a_tree_count"
                            {...register("gift_a_tree_count", {
                              required: false,
                              pattern: /^[0-9]*$/,
                              min: 0,
                            })}
                            value={count}
                            className="form-control"
                            autoComplete="new-password"
                          />
                          <button type="button" onClick={increment}>
                            +
                          </button>
                        </div>
                        <span className="error_validation ml-3">
                          {errors.gift_a_tree_count?.type === "required" &&
                            "This field is required."}
                          {errors.gift_a_tree_count &&
                            errors.gift_a_tree_count.type === "min" &&
                            count < 1 && (
                              <span>Please select a minimum of one tree</span>
                            )}
                          {errors.gift_a_tree_count &&
                            errors.gift_a_tree_count.type === "pattern" && (
                              <span>Please select a minimum of one tree</span>
                            )}
                        </span>
                        <br></br>
                        {(raceType === "Global Run" ||
                          raceType === "Timed Run") && (
                          <p className="note_para" style={{ marginBottom: "0px" }}>
                            Gift a Tree at Rs. 500 per Tree.
                          </p>
                        )}

                        {raceType === "Overseas" && (
                          <p className="note_para" style={{ marginBottom: "0px" }}>
                            Gift a Tree at USD 24 (Rs. 2000) per Tree.
                          </p>
                        )}
                        <span>Sponsor a tree with Geo Tagged Locations</span>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label className="form-label" htmlFor="gift_a_run">
                          Gift a Run
                        </label>
                        <div className="input-with-buttons">
                          <button type="button" onClick={runDecrement}>
                            -
                          </button>
                          <input
                            type="text"
                            id="gift_a_run"
                            {...register("gift_a_run", {
                              required: false,
                              pattern: /^[0-9]*$/,
                              min: 0,
                            })}
                            value={runCount}
                            className="form-control"
                            autoComplete="new-password"
                          />
                          <button type="button" onClick={runIncrement}>
                            +
                          </button>
                        </div>
                        <span className="error_validation ml-3">
                          {errors.gift_a_run?.type === "required" &&
                            "This field is required."}
                          {errors.gift_a_run &&
                            errors.gift_a_run.type === "min" &&
                            runCount < 1 && (
                              <span>Please select a minimum of one tree</span>
                            )}
                          {errors.gift_a_run &&
                            errors.gift_a_run.type === "pattern" && (
                              <span>Please select a minimum of one tree</span>
                            )}
                        </span>
                        <br></br>
                        {(raceType === "Global Run" ||
                          raceType === "Timed Run") && (
                          <p className="note_para" style={{ marginBottom: "0px" }}>
                            Gift a Run at Rs. 600 per Run.
                          </p>
                        )}
                        {raceType === "Overseas" && (
                          <p className="note_para" style={{ marginBottom: "0px" }}>
                            Gift a Run at USD 12 (Rs. 1000) per Run.
                          </p>
                        )}
                        <span>Sponsor a runner’s participation anonymously</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    {!showBib?.includes("virtual") && !showBib?.includes("1.5 KM Family Run") &&
                      raceType === "Timed Run" && (
                        <>
                          <h3 className="mt-3"> BIB Details </h3>
                          <div className="row mb-3">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  className="form-label"
                                  htmlFor="t_shirt_size"
                                >
                                  T-Shirt Size{" "}
                                  <span className="requiredColor">*</span>
                                </label>
                                <br></br>
                                {/* T-Shirt Will not be provided */}
                                <select
                                  name="t_shirt_size"
                                  className="form-control"
                                  {...register("t_shirt_size", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  {tShirtSizes.map((item, index) => {
                                    return (
                                      <option
                                        key={index + 1}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                <span className="error_validation ml-3">
                                  {errors.t_shirt_size?.type === "required" &&
                                    "This field is required."}
                                </span>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="bib_collection_location"
                                  className="form-label"
                                >
                                  BIB Collection Location
                                  <span className="requiredColor">*</span>
                                </label>
                                <select
                                  name="bib_collection_location"
                                  className="form-control"
                                  {...register("bib_collection_location", {
                                    required: true,
                                  })}
                                >
                                  <option value="">Select</option>
                                  {bibLocation.map((item, index) => {
                                    return (
                                      <option
                                        key={index + 1}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                                {errors.bib_collection_location?.type ===
                                  "required" && (
                                  <span className="error_validation ml-3">
                                    {errors.bib_collection_location?.type ===
                                      "required" && "This field is required."}
                                    <br />
                                  </span>
                                )}
                                <p className="mb-0 note_para ios-lineheight">
                                  1. BIB Will Not Be Distributed on the Race
                                  Day.
                                </p>
                                <p className="mb-0 note_para ios-lineheight">
                                  2. Collect your Race Kit on the BIB Distribution Day.
                                </p>
                                <p className="note_para ios-lineheight">
                                  3. Please share your unique runner ID during BIB collection.
                                </p>
                              </div>
                            </div>
                          </div>
                          
                        </>
                      )}
                {!showBib?.includes("virtual") && raceType === "Timed Run" && (
                    <>
                    <div className="row mt-2">
                            <div className="col-md-4">
                              <div className="form-group">
                                <label
                                  htmlFor="overnight_accommodation"
                                  className="form-label"
                                >
                                  Overnight Dormitory Accommodation (Rs. 500 will be
                                  charged)
                                  <span className="requiredColor">*</span>
                                </label>
                                <select
                                  name="overnight_accommodation"
                                  className="form-control"
                                  {...register("overnight_accommodation", {
                                    required: true,
                                  })}
                                  onChange={(e) => {
                                    setAccommidationStatus(e.target.value);
                                    setValue(
                                      "overnight_accommodation",
                                      e.target.value,
                                      true
                                    );
                                    clearErrors("overnight_accommodation");
                                  }}
                                >
                                  <option value="">Select</option>
                                  <option value="Y">Yes</option>
                                  <option value="N">No</option>
                                </select>
                                <span className="error_validation ml-3">
                                  {errors.overnight_accommodation?.type ===
                                    "required" && "This field is required."}
                                </span>
                              </div>
                              <br />
                              <div className="">
                                {accommidationStatus === "Y" && (
                                  <>
                                    <label className="form-label">
                                      Accommodation Images
                                    </label>
                                    <br />
                                    <SimpleReactLightbox className="test">
                                      <SRLWrapper>
                                        {accommodation &&
                                          accommodation.map((data, index) => {
                                            return (
                                              <div
                                                className="recent-plantation-section-plantation"
                                                key={index + 1}
                                              >
                                                <div
                                                  className="img-wrapper img_wrp hand-pointer"
                                                  key={data}
                                                >
                                                  <img
                                                    src={data}
                                                    className="img-fluid image-pad-4"
                                                    alt=""
                                                    width="125px"
                                                    height="70px"
                                                  />
                                                  <div className="overlay overLay-img">
                                                    <i className="icon icon-arrows-fullscreen"></i>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </SRLWrapper>
                                    </SimpleReactLightbox>
                                  </>
                                )}
                              </div>
                            </div>

                          </div>
                      <div className="row">
                        <div className="col-md-12 mt-2">
                          <p>
                            <strong>Note:</strong> For Hotel Accommodation at The Pearl, Click
                            here{" "}
                            <a
                              href="https://pearlhotel.in/book-now/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://pearlhotel.in/book-now/
                            </a>
                          </p>
                        </div>
                      </div>
                    </>
                    )}


                    {(showBib?.includes("399") || showBib?.includes("599")) && raceType === "Global Run" && (
                      <>
                        <div className="row">
                          {/* <div className="col-md-4">
                            <div className="form-group">
                              <label
                                htmlFor="bib_collection_location"
                                className="form-label"
                              >
                                Choose your Race Kit Collection Location
                                <span className="requiredColor">*</span>
                              </label>
                              <select
                                name="bib_collection_location"
                                className="form-control"
                                {...register("bib_collection_location", {
                                  required: true,
                                })}
                              >
                                <option value="">Select</option>
                                {globalbibLocation.map((item, index) => {
                                  return (
                                    <option key={index + 1} value={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </select>
                              {errors.bib_collection_location?.type ===
                                "required" && (
                                <span className="error_validation ml-3">
                                  {errors.bib_collection_location?.type ===
                                    "required" && "This field is required."}
                                  <br />
                                </span>
                              )}
                              <p className="mb-0 note_para">
                              For home address only, additional delivery charges Rs.119/- 
                              </p>
                            </div>
                          </div> */}
                          {/*  {showBib?.includes("599") && ( */}
                          <div className="col-md-4">
                            <div className="form-group">
                              <label
                                className="form-label"
                                htmlFor="t_shirt_size"
                              >
                                T-Shirt Size{" "}
                                <span className="requiredColor">*</span>
                              </label>
                              <select
                                name="t_shirt_size"
                                className="form-control"
                                {...register("t_shirt_size", {
                                  required: true,
                                })}
                              >
                                <option value="">Select</option>
                                {tShirtSizes.map((item, index) => {
                                  return (
                                    <option key={index + 1} value={item.value}>
                                      {item.label}
                                    </option>
                                  );
                                })}
                              </select>
                              <span className="error_validation ml-3">
                                {errors.t_shirt_size?.type === "required" &&
                                  "This field is required."}
                              </span>
                            </div>
                          </div>
                          {/*  )} */}
                        </div>
                      </>
                    )}

                    {showBib?.includes("400") && (
                      <>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="form-group">
                              <input
                                {...register("terms", {
                                  required: true,
                                })}
                                type="checkbox"
                              />
                              <label htmlFor="agree">
                                {" "}
                                I Agree to the{" "}
                                <b>
                                  <a href="/terms/" target="_blank">
                                    terms and conditions
                                  </a>
                                </b>
                              </label>
                              <br></br>
                              <span className="error_validation ml-3">
                                {errors.terms?.type === "required" &&
                                  "This field is required."}
                              </span>

                              {/*    <input type="checkbox" id="agree"  required />
          <label htmlFor="agree"> I agree to <b>terms and conditions</b></label>
                        */}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    <div style={{ float: "right" }} className="form-label">
                      &nbsp;
                      {(pList == null || pList?.length <= 5) && (
                        <div>
                          {!isEdit && (
                            <button
                              type="button"
                              className=" btn btn-secondary btn-block"
                              value="Clear"
                              onClick={() => {
                                // reset();
                                // setSelectedCityOption("");
                                // setAccommidationStatus("");
                                // setIsEdit(false);
                                // lStorage.remove("editSecretId");
                                // raceCatSelected();
                                clearModal();
                              }}
                              disabled={pList != null && pList?.length >= 5}
                            >
                              Clear
                            </button>
                          )}
                          {isEdit && (
                            <button
                              type="button"
                              className=" btn btn-secondary btn-block"
                              onClick={() =>
                                navigate("/successfulparticipants")
                              }
                            >
                              {" "}
                              Back
                            </button>
                          )}
                          &nbsp;&nbsp;
                          {!isEdit && (
                            <input
                              type="submit"
                              className="btn btn-primary btn-block"
                              value={isEdit ? "Update" : "Submit"}
                              disabled={
                                isContactValid === false ||
                                (isEcontactValid === false && true) ||
                                (pList != null && pList?.length >= 5)
                              }
                            />
                          )}
                          {isEdit && (
                            <input
                              type="submit"
                              className="btn btn-primary btn-block"
                              value={isEdit ? "Update" : "Submit"}
                              disabled={
                                isContactValid === false ||
                                (isEcontactValid === false && true)
                              }
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              {/* {!isMobile && formData && formData?.length > 0 && (
                <div className="col-md-3">
                  <div className="participantsform-wrapper">
                    <div>
                      <h3> Participants </h3>
                      {formData &&
                        formData.map((data, index) => {
                          return (
                            <div
                              className="participants-box-wrapper first-upper"
                              key={index + 1}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div>
                                  <span className="particiapnts-icon">
                                    <img
                                      id="preview"
                                      width="35px"
                                      src={CreateImageFromInitials(
                                        500,
                                        data.first_name,
                                        GetRandomColor()
                                      )}
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <div
                                    className="deleteiocn"
                                    onClick={() => {
                                      editData(data);
                                      setIsEdit(true);
                                    }}
                                    style={{
                                      marginRight: "35px",
                                    }}
                                    title="Edit"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="15"
                                      fill="currentColor"
                                      class="bi bi-pen"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001zm-.644.766a.5.5 0 0 0-.707 0L1.95 11.756l-.764 3.057 3.057-.764L14.44 3.854a.5.5 0 0 0 0-.708l-1.585-1.585z" />
                                    </svg>
                                  </div>{" "}
                                  <div
                                    className="deleteiocn"
                                    onClick={() => {
                                      deleteData(data);
                                      setIsDeleted(pList?.length - 1);
                                    }}
                                    title="Delete"
                                  >
                                    <img
                                      className="img-fluid"
                                      src={deletebin}
                                      alt="Clock logo"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <p>
                                  <b>Name :</b> {data.first_name}{" "}{data.last_name}
                                </p>
                                <p>
                                  {" "}
                                  <span>
                                    <b>Gender :</b> {data.gender}
                                  </span>
                                </p>
                                <span>
                                  <b>Race Category :</b> {data.race_km}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      {((pList && pList === null) ||
                        (pList && pList.length === 0)) && (
                        <span>No Participants</span>
                      )}

                      <Button
                        className="btn hand viewbutton"
                        disabled={pList?.length === 0 ? true : false}
                        onClick={() => {
                          navigate("/successfulparticipants");
                        }}
                      >
                        {" "}
                        View Summary & Pay
                      </Button>
                    </div>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>Global Run conducted at Heartfulness Centers</b>
          </p>
          <p>{centerList}</p>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
}
